.table-holder {
    font-size: 0.857rem;
    margin-top: 1.375rem;

    .mat-table {
        width: 100%;
        background: transparent;
        border: 1px solid #d8d8d8;
        border-collapse: separate;
        width: 100%;

        td {
            display: table-cell;
            vertical-align: middle;
        }

        .mat-header-row th,
        .mat-row td {
            text-align: left;

            &:last-child {
                border-right: none;
            }
        }

        .mat-header-row th {
            background-color: transparent;
            color: #969696;
            height: 3.714rem;
            padding: 0.625rem;
            font-size: 0.6875rem;

            img {
                width: 8px;
            }
        }

        th.mat-header-cell,
        td.mat-cell,
        td.mat-footer-cell {
            padding: 0.5rem 1rem !important;
            font-size: 0.75rem;
        }

        th.mat-header-cell {
            color: var(--black);
            font-weight: bold;
            font-size: 0.7rem;
        }

        td.mat-cell {
            border-bottom: none !important;
            line-break: auto;
        }

        .mat-column-name {
            max-width: 200px;
        }

        .mat-column-modified-by {
            overflow-wrap: anywhere;
        }

        .mat-row:nth-child(even) {
            background-color: #f0f0f0;
        }

        .mat-header-row {
            border-bottom: 1px solid #d8d8d8;
        }

        .mat-footer-row {
            border-bottom: 2px solid #f0f0f0;
            background: white;
        }

        .mat-row {
            cursor: default;

            td {
                font-size: 0.75rem;
                height: 3.8125rem;
                padding: 0.625rem;
            }
        }
    }
}

.no-data {
    text-align: center;
    background-color: var(--white);
    padding: 1rem;
}

.pagination {
    font-weight: 700;
}

.mat-table {
    .mat-sort-header-arrow {
        background-image: url('/assets/img/icons/dropdown_black_icon@2x.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        transform: translateY(0%) !important;
        opacity: 0.7 !important;
        height: 0.625rem;
        width: 0.625rem;
        min-width: 0.625rem;

        .mat-sort-header-stem {
            display: none !important;
        }

        .mat-sort-header-indicator {
            .mat-sort-header-pointer-left,
            .mat-sort-header-pointer-right,
            .mat-sort-header-pointer-middle {
                display: none !important;
            }
        }
    }

    .mat-sort-header-sorted {
        .mat-sort-header-arrow {
            color: var(--dark-blue);
            opacity: 1 !important;
        }

        .mat-sort-header-stem {
            display: block;
        }

        .mat-sort-header-indicator {
            .mat-sort-header-pointer-left,
            .mat-sort-header-pointer-right,
            .mat-sort-header-pointer-middle {
                display: block;
            }
        }
    }

    .mat-header-row .mat-header-cell[aria-sort='descending'] {
        .mat-sort-header-arrow {
            transition: 0.1s ease-in-out;
        }
    }

    .mat-header-row .mat-header-cell[aria-sort='ascending'] {
        .mat-sort-header-arrow {
            transform: rotate(180deg) !important;
            transition: 0.1s ease-in-out;
        }
    }
}

.oval-status {
    border-radius: 0.25rem;
    width: fit-content;
    padding: 3.55% 8%;
    display: inline-flex;
    align-items: center;
    margin: 0.25rem;
    font-weight: bold;
    font-size: 0.75rem;
    min-width: 70px;
    justify-content: center;
    white-space: nowrap;

    &.oval-status-detail {
        padding: 0.5rem 2rem;
        margin: unset;
    }

    &.active,
    &.approved {
        background-color: var(--success);
        color: white;
    }

    &.in-progress,
    &.pending {
        background-color: var(--light-grey);
        color: white;
    }

    &.pending-approval {
        background-color: var(--hariwang-orange);
        color: black;
    }

    &.suspended {
        background-color: var(--warning);
        color: white;
    }

    &.terminated,
    &.inactive,
    &.resigned,
    &.rejected,
    &.cancelled,
    &.locked {
        background-color: var(--red);
        color: white;
    }
}
