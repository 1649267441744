.cdk-live-announcer-element {
    display: none;
}

.cdk-overlay-container {
    z-index: 5000 !important;
}

.mat-datepicker-content {
    background-color: var(--white);
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0.214rem;

    .mat-calendar-table-header {
        color: var(--red);
    }

    .mat-calendar-body-disabled {
        opacity: 0.5;
    }

    .mat-calendar-body-cell-content {
        border-style: none;

        &:hover {
            background-color: var(--light-hover) !important;
        }
    }

    .mat-calendar-body-selected {
        background-color: var(--hariwang-orange);
        color: var(--black);
    }

    button {
        background-color: transparent;
    }

    .mat-raised-button {
        display: none;
    }
}

.mat-option:hover {
    background-color: #f4f4f4;
}

.mat-option-text {
    font-size: 0.857rem;
    color: black;
}

.mat-autocomplete-panel {
    box-shadow: 0px 3px 6px #00000029;
    background-color: white;
}

.mat-select-panel-wrap {
    .mat-select-panel {
        background-color: var(--white);
        box-shadow: 0 1px 0.3125rem rgba(0, 0, 0, 0.3);
    }

    .mat-option {
        .mat-option-text {
            font-size: 0.75rem;
        }

        &:hover {
            background-color: #f4f4f4;
        }
    }
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background: var(--hariwang-dark-orange);
}

.mat-checkbox-ripple .mat-ripple-element {
    background-color: var(--hariwang-dark-orange) !important;
}

.mat-checkbox-label {
    font-size: 0.75rem;
}

.mat-checkbox-frame {
    border: 1px solid #bebebe !important;
}

.mat-checkbox-layout {
    white-space: normal !important;
    margin-bottom: 0;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: #c4e7d3;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: #00af4a;
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
    background-color: var(--hariwang-dark-orange) !important;
}

.mat-radio-group {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--hariwang-dark-orange) !important;
}

.mat-radio-checked .mat-radio-inner-circle {
    background-color: var(--hariwang-dark-orange) !important;
}

.mat-radio-inner-circle,
.mat-radio-outer-circle {
    height: 1rem !important;
    width: 1rem !important;
}

.mat-radio-button .mat-radio-ripple {
    display: none !important;
}

.mat-radio-label-content {
    font-size: 0.75rem;
}

.mat-list-item-content {
    flex-direction: row !important;
}

.mat-list-option {
    height: 32px !important;
}

.mat-pseudo-checkbox {
    border: 1px solid #bebebe !important;
    margin-right: 8px;
}

.mat-pseudo-checkbox-checked {
    background: var(--hariwang-dark-orange) !important;
}

.mat-pseudo-checkbox::after {
    top: 3.4px !important;
    left: 2px !important;
}

.mat-list-text {
    color: #7c8589;
    font-size: 0.75rem;
}
