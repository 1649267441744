.dropdown {
    .dropdown-toggle::after {
        display: none;
    }

    &.show .dropdown-menu {
        opacity: 1;
        transition: 0s;
        top: calc(100% + 1rem) !important;
        left: auto !important;
        right: 0rem;
        transform: translate3d(0, 0, 0);
        padding: 0;
        border: none;
        box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.16);
        border-radius: 0.25rem;
        // width: 12.5rem;

        .disable-item {
            pointer-events: none;
            cursor: default;
            text-align: center;
            display: block;
        }
    }

    &.show .dropdown-menu.table-ngb-drop {
        top: 0 !important;
        transform: translate(0px, 35px) !important;
        // width: 10.5rem;

        .dropdown-item {
            text-align: center;
            font-weight: bold;

            &:active {
                background: white;
                color: #212529;
            }
        }
    }
}

.mat-form-field {
    padding: 0;
    border-radius: 0;
    border-color: #dcdcdc;
    color: #596882;
    height: 100%;

    mat-label {
        font-weight: 400;
        padding-top: 1rem;
        font-size: 0.75rem;
        color: #919191;
    }

    .mat-form-field-underline {
        display: none;
    }

    .mat-form-field-wrapper {
        padding: 0.425rem 0.625rem;
    }

    .mat-form-field-label {
        display: none;
    }

    .mat-form-field-infix {
        border-top: 0;
    }

    .mat-form-field-prefix {
        font-size: 1.4375rem;
        padding-top: 0.125rem;
    }

    &.mat-form-field-disabled {
        background-color: #e9ecef !important;
    }

    &.cust-title {
        .mat-select-arrow {
            color: #2f2f2f !important;
        }

        span {
            color: #2f2f2f !important;
            font-weight: 700;
        }
    }
}

.mat-form-field.listing-filter {
    line-height: 2 !important;
    height: 3.438rem;

    .mat-form-field-flex {
        background-color: transparent;
    }

    .mat-form-field-wrapper {
        padding: 0 0.25rem;
    }

    .mat-form-field-infix {
        padding: 0;
    }
}

.mat-form-field.mat-paginator-page-size-select {
    width: 4.375rem;

    .mat-form-field-wrapper {
        padding-bottom: 1.1rem;
    }

    .mat-standard-chip {
        border-radius: 5px;
    }
}

.mat-select-value {
    color: var(--black);
    font-weight: bold;
    font-size: 0.75rem;
}

.mat-select-search-panel {
    .mat-select-search-input {
        padding: 1rem 2.25rem 1rem 1rem !important;
    }

    .mat-select-search-clear {
        width: 1.6rem;
        height: 1.6rem;
        top: 50% !important;
        transform: translateY(-50%);
        right: 0.8rem;
        outline: none;
        line-height: 24px;

        .mat-button-focus-overlay {
            display: none;
        }
    }
}

textarea {
    min-height: 10rem;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: white;
    height: 3rem !important;
    padding: 0.75em 0 0 0 !important;
}

.chip-search-autocomplete {
    .mat-form-field-wrapper {
        padding: 0;

        .mat-form-field-infix {
            padding: 0;
        }
    }

    .mat-chip-list-wrapper {
        margin: 0 0.375rem;

        .mat-standard-chip {
            margin: 0.375rem 0.25rem;
        }

        input {
            border: 0;
            margin: 0 0.25rem !important;
        }
    }

    .mat-form-field-label {
        top: 1.5rem;
        left: 0.6rem;
    }
}
