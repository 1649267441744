@mixin size($width, $height) {
    width: $width / 16 * 1rem;
    height: $height / 16 * 1rem;
}

@mixin hariwangIcon() {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

@mixin bgimage($image) {
    background-image: url(../../../assets/img/#{$image});
}

[class^='hariwang-icon-'],
[class*='hariwang-icon-'] {
    @include hariwangIcon;
}

.hariwang-icon {
    @include size(24, 24);
}

.hariwang-icon-xs {
    @include size(20, 20);
}

.hariwang-icon-md {
    @include size(30, 30);
}

.hariwang-icon-app {
    @include bgimage('brand/hariwang_logo_big.png');
    width: 6.25rem;
    height: 2.063rem;
    cursor: default;
}

.hariwang-icon-dropdown {
    @include bgimage('icons/dropdown_black_icon@2x.png');
    @include size(8, 8);
}

.hariwang-icon-eye-on {
    @include bgimage('icons/icon-eye-on@2x.png');
    @include size(24, 24);
}

.hariwang-icon-eye-off {
    @include bgimage('icons/icon-eye-off@2x.png');
    @include size(24, 24);
}

.hariwang-icon-setting {
    @include bgimage('icons/setting_icon.png');
    @include size(20, 20);
}

.hariwang-icon-edit {
    @include bgimage('icons/edit_icon.png');
    @include size(20, 20);
}

.hariwang-icon-delete {
    @include bgimage('icons/delete_icon.png');
    @include size(20, 20);
}

.hariwang-icon-reset-password {
    @include bgimage('icons/reset_password_icon.png');
    @include size(20, 20);
}

.hariwang-icon-grey-tick {
    @include bgimage('icons/icon-password-contained-grey@2x.png');
    @include size(16, 16);
}

.hariwang-icon-green-tick {
    @include bgimage('icons/icon-password-contained-green@2x.png');
    @include size(16, 16);
}

.hariwang-icon-cross {
    @include bgimage('icons/cross_icon@2x.png');
    @include size(16, 16);
}

.hariwang-icon-back {
    @include bgimage('icons/back_bttn@2x.png');
    @include size(16, 16);
}

.hariwang-icon-dropdown-white {
    @include bgimage('icons/dropdown_white_icon@2x.png');
    @include size(10, 10);
}
