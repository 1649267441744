.modal-content {
    border: 1px solid silver;
    border-radius: 0;
}

.modal-header-holder {
    padding: 1.4285rem 1.785rem;

    .modal-title {
        font-size: 2.285rem;
        font-weight: bold;
        float: left;
    }

    .but-close {
        color: #000000;
        font-size: 1.14285rem;
        line-height: 2.285rem;
        float: right;
    }
}
