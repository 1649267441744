.align-center {
    align-self: center;
    align-items: center;
}

.float-right {
    float: right;
}

.media {
    aspect-ratio: 16/9;
    background-image: url('/assets/img/icons/img-placeholder-rectangle@2x.png');
    background-repeat: no-repeat;
    background-size: contain;

    > img,
    > video {
        width: 100%;
        height: 100%;
        border: 1px solid #dddddd;
    }
}

iframe {
    width: 100%;
    height: 100%;
}

.green-box,
.grey-box,
.red-box {
    width: 100%;
    color: white;
    text-transform: uppercase;
    min-height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 0.8125rem;
}

.green-box {
    background-color: #00c345;
}

.grey-box {
    background-color: #707070;
}

.red-box {
    background-color: #ff5353;
}

.detail-title {
    font-size: 1.25rem;
    font-weight: bold;
}

.detail-section-title {
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
}

.detail-label {
    font-size: 0.75rem;
    font-weight: 400;
}

.detail-data {
    font-size: 0.75rem;
    font-weight: bold;
}
