form {
    .section-title {
        color: #242526;
        font-size: 1rem;
        font-weight: bold;
        padding-bottom: 1.5rem;
    }

    .field-title {
        font-size: 0.75rem;
        color: #242526;
        font-weight: 600;
        margin-bottom: 0.9375rem;
    }

    .field-holder.has-prefix {
        position: relative;

        .field-prefix {
            position: absolute;
            top: 0.7rem;
            left: 0.5rem;
            font-size: 0.75rem;
            color: #596882;
            font-weight: bold;
            border-right: 1px solid;
            padding-right: 6px;
            cursor: default;
        }

        input {
            padding-left: 3rem;
        }
    }

    .field-subtitle {
        font-size: 0.75rem;
        color: #525355;
        margin-bottom: 0.9375rem;
    }

    .field-title-required::after {
        content: ' *';
        color: red;
    }

    textarea {
        border: 1px solid #e8e8e8 !important;
    }

    input,
    textarea,
    .mat-form-field {
        background: var(--white);
        border: 1px solid #e8e8e8;
        border-radius: 0;
        margin-bottom: 0;
        min-height: 2.5rem;
        font-size: 0.75rem;
    }

    ::placeholder {
        color: #919191;
        font-size: 0.75rem;
    }

    &.show-form-error {
        .ng-invalid,
        .ng-invalid-manual {
            border: 1px solid #ee1d23 !important;
        }

        .mat-select-invalid,
        .mat-select-empty {
            border: none !important;
        }

        .image-button-error {
            border: 1px solid #ee1d23 !important;
        }
    }

    .form-group {
        margin-bottom: 0;
    }

    agm-map {
        height: 18.75rem;
    }

    input[readonly] {
        background-color: white !important;
    }

    .word-limit-holder {
        position: relative;
        margin-bottom: 1rem;

        .word-limit {
            position: absolute;
            right: 0.5rem;
            bottom: 0;
            font-size: 0.75rem;
            font-style: italic;
            color: #bebebe;
        }
    }

    .currency-holder {
        position: relative;

        input {
            padding-left: 2.5rem;
        }

        .currency {
            position: absolute;
            text-transform: uppercase;
            bottom: 11px;
            left: 25px;
            font-size: 0.75rem;
            color: #7c8589;
        }
    }

    .form-divider {
        margin-left: -32px;
        margin-right: -32px;
    }

    .input-with-spinner {
        position: relative;

        .spinner-container {
            display: block;
            position: absolute;
            bottom: 10px;
            right: 11px;

            .fas {
                font-family: 'FontAwesome';
                font-style: normal;
                font-weight: 900;
                font-size: 19px;
                display: block;
                width: max-content;
            }
        }
    }
}

.submit-holder {
    justify-content: flex-end;
    margin: 0;

    .btn-primary {
        min-width: 6.625rem;
    }

    .btn-cancel {
        min-width: 6.625rem;
    }
}

.form-control {
    border-radius: 0;

    &:focus {
        box-shadow: unset;
    }
}

input[type='text'],
input[type='password'],
input[type='number'],
textarea {
    font-family: 'Open Sans', sans-serif;
    color: #596882;
    border: 1px solid #dcdcdc;
    height: calc(1.5em + 0.75rem + 4px);
    border-radius: 0;
    font-size: 0.75rem !important;
}

textarea {
    font-family: 'Open Sans', sans-serif;
    color: #596882;
    border: 1px solid #dcdcdc;
    border-radius: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.custom-checkbox {
    margin-bottom: 1rem;
    display: flex;
    align-items: flex-end;

    &:last-child {
        margin-bottom: 0;
    }

    input[type='checkbox'] {
        width: 1.25rem;
        height: 1.25rem;
        margin-left: 0.65rem;
    }

    label {
        padding-left: 0.9375rem;
    }
}

.custom-radio {
    margin-right: 1.875rem;
    display: inline-block;
    vertical-align: top;

    input[type='radio'] {
        display: none;
    }

    label {
        padding-left: 1.5rem;
        margin-bottom: 0;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        position: relative;

        &:before,
        &:after {
            border-radius: 50%;
            content: '';
            position: absolute;
        }

        &:before {
            background-color: var(--white);
            width: 1rem;
            height: 1rem;
            border: 1px solid var(--dark-blue);
            top: 0.285rem;
            left: 0;
        }
    }

    input[type='radio']:checked + label:after {
        background-color: var(--dark-blue);
        width: 0.57rem;
        height: 0.57rem;
        top: 0.5rem;
        left: 0.214rem;
    }
}

.form-error-msg {
    margin: -1rem 0rem 1rem 0.5rem;
}

.error-msg {
    color: #ff0000;
    font-size: 0.75rem;
    font-weight: bold;
}

.preview-container {
    display: flex;
    justify-content: center;

    .preview {
        position: relative;
        text-align: center;
        color: #888888;

        .col-image {
            height: 7.5rem;
            width: 7.5rem;
            position: relative;
            border: 1px solid #dddddd;

            .img_select {
                width: 100%;
                height: 100%;
                object-fit: contain;
                font-family: 'object-fit: contain'; // object-fit-images fix for ie
            }
        }
    }
}

.rectangle-preview-wrapper {
    position: relative;

    .rect-image-holder {
        background-color: white;
        border: 1px solid #dddddd;
    }

    .rect-image {
        object-fit: contain;
        width: 100%;
    }

    .col-image {
        position: relative;
        cursor: pointer;

        &.empty {
            background-color: #0055a5;
        }
    }

    i {
        color: #cecece;

        &:hover {
            cursor: pointer;
        }

        &.delete-preview {
            top: 0.2rem;
            right: 0.1rem;
            position: absolute;
        }
    }
}

.form-toggle-wrapper {
    padding-top: 0.5rem;
    position: relative;

    .toggle-text {
        position: absolute;
        z-index: 1;
        font-size: 12px;
        top: 0.7rem;

        &.activated {
            left: 0.625rem;
            color: black;
        }

        &.deactivated {
            left: 3rem;
            color: white;
        }
    }

    .mat-slide-toggle-bar {
        height: 1.5rem !important;
        width: 6.25rem !important;
        border-radius: 20px !important;
    }

    .mat-slide-toggle-thumb {
        height: 1.75rem !important;
        width: 1.75rem !important;
    }

    .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
        transform: translate3d(75px, 0, 0) !important;
    }

    .mat-slide-toggle-thumb-container {
        height: 1.75rem !important;
        width: 1.75rem !important;
        top: -0.125rem !important;
    }
}

.form-container {
    background-color: white;
    box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
}

.form-wrapper {
    padding: 1rem 2rem;

    .title {
        font-size: 1.25rem;
        font-weight: bold;
        color: #242526;
    }

    .sub-title {
        font-size: 1rem;
        font-weight: bold;
        color: #242526;
    }

    .description {
        font-size: 0.75rem;
        color: #525355;
    }

    .field-title {
        font-size: 0.75rem;
        font-weight: bold;
        color: #242526;
    }
}

.action-button-container {
    text-align: center;
    padding-top: 1rem;
    display: flex;
    justify-content: center;

    .btn-orange {
        font-weight: bold;
    }

    button {
        text-transform: uppercase;
        min-width: 182px;
        height: 53px !important;
    }
}

.upload-message {
    font-size: 0.75rem;
    color: #525355;
    text-align: center;
    padding-top: 0.625rem;
}
