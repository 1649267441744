button {
    &:disabled {
        cursor: not-allowed !important;
    }
}

.btn {
    position: relative;
    transition: all 0.15s ease;
    letter-spacing: 0.025em;
    font-size: 0.875rem;
    text-align: center;
    color: var(--white);
    border-radius: 0.25rem;
    min-height: 2.5rem;
    font-weight: bold;

    &:hover {
        transform: translateY(-1px);
    }
    // Icons
    i:not(:first-child),
    svg:not(:first-child) {
        margin-left: 0.5rem;
    }

    i:not(:last-child),
    svg:not(:last-child) {
        margin-right: 0.5rem;
    }
}

@mixin button-color($color, $background, $border) {
    color: $color;
    background-color: $background !important;
    border-color: $border !important;

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active,
    &:not([disabled]):not(.disabled):hover,
    &:not([disabled]):not(.disabled).hover,
    &:not([disabled]):not(.disabled):focus,
    &:not([disabled]):not(.disabled).focus,
    .show > &.dropdown-toggle {
        color: $color;
        background-color: darken($background, 10%) !important;
        border-color: darken($border, 10%) !important;
    }
}

button:focus {
    outline: none !important;
    box-shadow: none !important;
}

.btn-beige {
    @include button-color(#0054a5, #ffd88e, #ffd88e);
}

.btn-blue {
    @include button-color(#ffffff, #0054a5, #0054a5);
}

.btn-black {
    @include button-color(#ffffff, #000000, #000000);
}

.btn-clear-filter {
    @include button-color(#000000, transparent, #000000);
    border: 2px solid;
    border-radius: 0;
}

.btn-listing-radius {
    border-radius: 0;
}

.btn-blue-alt {
    @include button-color(#0054a5, #ffffff, #0054a5);
}

.btn-orange {
    @include button-color(black, #fcbc03, #fcbc03);
}

.btn-purple {
    @include button-color(white, #34346a, #34346a);
}

.btn-pink {
    @include button-color(white, #e72d72, #e72d72);
}
