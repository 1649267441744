//import//
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@angular/cdk/overlay-prebuilt.css';
@import '~ng-pick-datetime/assets/style/picker.min.css';
@import './app/shared/services/popup/popup.scss';
@import './app/shared/styles/buttons.scss';
@import './app/shared/styles/colors.scss';
@import './app/shared/styles/details.scss';
@import './app/shared/styles/dropdown.scss';
@import './app/shared/styles/font.scss';
@import './app/shared/styles/form.scss';
@import './app/shared/styles/icon.scss';
@import './app/shared/styles/modal.scss';
@import './app/shared/styles/table.scss';
@import 'material.scss';

:root {
    --primary: #242526;
    --primary-hover: #242526;
    --secondary: #eaf9ff;
    --title: #242526;
    --edit: #4e94ff;
    --success: #08a32c;
    --success-hover: #08a32c;
    --light: #cdd4e0;
    --light-hover: #cdd4e0;
    --dark: #3b4863;
    --dark-hover: #293245;
    --warning: #ffc107;
    --warning-hover: #e6ae07;
    --danger: #e95f09;
    --progress: #3366ff;
    --red: #ec445e;
    --red-hover: #f02d43;
    --blue: #3d6cff;
    --dark-blue: #004fac;
    --orange: #ff7a3e;
    --green: #3bb001;
    --dark-grey: #959595;
    --light-grey: #bebebe;
    --white: #ffffff;
    --hariwang-orange: #fcbc03;
    --hariwang-dark-orange: #daa203;
    --black: black;
    --white: white;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    height: 100%;
    font-family: 'Open Sans', sans-serif;
}

html {
    font-size: 16px;
}

body {
    background-color: $white;
    padding: 0;
    margin: 0;
    color: var(--black);
    position: relative;
}

//
table,
tr,
td,
th {
    border-collapse: collapse;
}

::placeholder {
    color: #919191;
    font-size: 0.75rem;
}

//
.clearfix {
    &:after {
        clear: both;
        content: '';
        display: block;
    }
}

//
*[hidden],
.hide {
    display: none !important;
}

.pointer {
    cursor: pointer !important;
}

.content-listing {
    background-color: white;
}

.content-inside {
    background-color: #f7f7f7;
    height: 100%;
}

.main-content {
    width: 100%;
    height: 100%;
    position: relative;
    overflow-x: hidden;
    min-height: 100vh;
    background-color: white;
}

.content-wrapper {
    padding: 0 1.5rem 1rem;

    .main-title {
        color: var(--title);
        font-size: 1.5rem;
        line-height: 1em;
        font-weight: bold;
    }

    .main-desc {
        color: var(--dark-blue);
        font-size: 1.4285em;

        &.alert-desc {
            color: #ff0000;
        }
    }

    .wrapper {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        position: relative;
    }

    .content-top {
        padding: 1.625rem 0;
        display: flex;
        align-items: center;

        [class^='-back'],
        [class*='-back'] {
            align-self: center;
        }
    }

    .content-top-button {
        text-align: right;

        .btn {
            margin-left: 0.357rem;
            vertical-align: middle;
        }
    }

    .content-holder {
        padding-bottom: 1rem;

        .content-box {
            background-color: var(--white);
            padding: 1.875rem;
            border-radius: 10px;
        }

        .listing-filter {
            height: 3.313rem;
        }

        .btn-clear-filter {
            height: 2.563rem;
            width: 5.875rem;
            float: right;
            font-weight: bold;
            font-size: 0.875rem;
        }

        .btn-blue {
            height: 2.563rem;
            width: 5.75rem;
            font-weight: 500;
            font-size: 0.875rem;
        }

        .align-center {
            align-items: center;
        }
    }

    .flex-center-container {
        display: flex;
        align-items: center;
    }
}

.owl-dt-calendar-cell-selected {
    background-color: var(--hariwang-orange) !important;
    color: var(--black) !important;
}

.tox-menu {
    z-index: 2004 !important;
}

.tox-fullscreen {
    z-index: 2002 !important;
}

.tox-fullscreen .tox.tox-tinymce-aux {
    z-index: 2003 !important;
}

.tox-dialog .tox-dialog__content-js .tox-dialog__body .tox-form__controls-h-stack input[type=url].tox-textfield {
    pointer-events: none !important
}

.tox-dialog .tox-dialog__content-js .tox-dialog__body div.tox-form__group:first-child label {
    pointer-events: none !important
}

.tox-dialog .tox-dialog__content-js .tox-dialog__body .tox-form__controls-h-stack div.tox-form__group:first-child label {
    pointer-events: none !important
}