@font-face {
    font-family: 'Open Sans';
    src: url('/assets/font/OpenSans/OpenSans-Light.ttf');
    font-weight: 300;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/assets/font/OpenSans/OpenSans-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/assets/font/OpenSans/OpenSans-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/assets/font/OpenSans/OpenSans-SemiBold.ttf');
    font-weight: 600;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/assets/font/OpenSans/OpenSans-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'Open Sans';
    src: url('/assets/font/OpenSans/OpenSans-ExtraBold.ttf');
    font-weight: 800;
}
